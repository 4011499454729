import React from "react"
import { Post } from "@components/Blog"
import { Button } from "@components/Button"

export const RelatedArticles = ({ articles }) => {
  return (
    <>
      <div className="bg-teal-light pt-clamp-32-60">
        <div className="relative container">
          <div className="absolute inset-0 flex pointer-events-none">
            <div className="mt-auto aspect-w-303 aspect-h-121 sm:aspect-w-1436 sm:aspect-h-266 before:bg-white-dark after:bg-white-dark extend"></div>
          </div>
          <div className="lg:w-10/12 mx-auto flex justify-between items-center">
            <div>
              <h4 className="text-clamp-26-42">Related articles.</h4>
            </div>
            <div>
              <Button link="/blog" className="text-gray-darkest sm:hover:text-gray-darkest">
                View all
              </Button>
            </div>
          </div>
          <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-clamp-12-24 mt-14">
            {articles.map((post, index) => {
              return <Post key={index} post={post} />
            })}
          </div>
        </div>
      </div>
    </>
  )
}
