import React, { useState } from "react"

import ArrowLeft from "@svg/ui/arrow-left.svg"
import ArrowRight from "@svg/ui/arrow-right.svg"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"

export const Button = ({
  children,
  link,
  className = "text-gray-darkest sm:hover:text-blue",
  external = false,
  reverse = false,
  func = null,
}) => {
  const [isHovered, setHover] = useState(false)

  const clickHandler = () => {
    if (func == null) {
      return
    }
    func()
  }

  return (
    <button
      onClick={() => clickHandler()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${className} sm:hover:underline font-bold flex items-center transition-all duration-300`}
    >
      {reverse && (
        <CSSTransition in={isHovered} timeout={300} classNames="button-arrow-reverse">
          <div className="flex mr-2 fill-current" style={{ width: "20px" }}>
            <ArrowLeft />
          </div>
        </CSSTransition>
      )}

      {external ? (
        <a href={link} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <Link to={link || ""}>{children}</Link>
      )}

      {!reverse && (
        <CSSTransition in={isHovered} timeout={300} classNames="button-arrow">
          <div className="flex ml-2 fill-current" style={{ width: "20px" }}>
            <ArrowRight />
          </div>
        </CSSTransition>
      )}
    </button>
  )
}
