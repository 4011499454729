import Ana from "@svg/certificates/ana.svg"
import CertSmallBusiness from "@svg/certificates/small-business.svg"
import CertWBENC from "@svg/certificates/wbenc.svg"
import Instagram from "@svg/social/instagram.svg"
import LinkedIn from "@svg/social/linkedin.svg"
import { Link } from "gatsby"
import React from "react"

export const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="py-16 pt-clamp-14-25">
      <div className="container footer md:grid">
        <div className="flex footer-certs">
          <div className="flex mx-auto md:ml-auto md:mr-0 gap-x-5">
            <Link
              to="/blog/jk-design-recognized-as-a-wbenc-certified-womens-business-enterprise/"
              aria-label="WBENC Certified Womens Business Enterprise"
              className="w-24"
            >
              <CertWBENC />
            </Link>
            <a
              href="https://www.ana.net/miccontent/show/id/ii-diverse-supplier-resource-list"
              target="_blank"
              rel="noreferrer"
              aria-label="Diverse Supplier Resource List"
              className="w-24"
            >
              <Ana />
            </a>
            <Link
              to="/blog/jk-design-recognized-as-a-wbenc-certified-womens-business-enterprise/"
              aria-label="Certified Small Business Enterprise"
              className="w-24"
            >
              <CertSmallBusiness />
            </Link>
          </div>
        </div>

        <div className="flex justify-center footer-social md:justify-start mt-9 md:mt-0">
          <div className="flex items-center mt-auto text-14 gap-x-4" style={{ height: "1.5rem" }}>
            <a
              href="https://linkedin.com/company/jk-design/mycompany/"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
              className="block transition-all duration-300 fill-current text-gray-darkest sm:hover:text-teal"
            >
              <LinkedIn />
            </a>
            <a
              href="https://www.instagram.com/jk.design/"
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
              className="block transition-all duration-300 fill-current text-gray-darkest sm:hover:text-teal"
            >
              <Instagram />
            </a>
          </div>
        </div>

        <div className="footer-nav-secondary md:flex">
          <nav className="flex justify-center mt-8 md:justify-start md:ml-auto">
            <div className="flex font-book text-14">
              <Link to="/careers" className="sm:hover:underline">
                Careers
              </Link>
              <span className="px-2">|</span>
              <Link to="/privacy-policy" className="sm:hover:underline">
                Privacy policy
              </Link>
              <span className="px-2">|</span>
              <Link to="/terms-of-service" className="sm:hover:underline">
                Terms of service
              </Link>
            </div>
          </nav>
        </div>

        <div className="md:flex footer-copyright">
          <div className="mt-3 text-center md:mt-auto font-book text-14 md:text-left">
            &copy; {currentYear} JK Design. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  )
}
