import { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { settingsContext } from "@context/provider"

export const usePageSetup = ({ themeColor, navigationColor = "text-gray-darkest", displayGeneralForm = true }) => {
  const [, dispatchSettings] = useContext(settingsContext)

  useEffect(() => {
    dispatchSettings({ type: "SET_CURSOR", payload: { display: false, drag: false } })
    dispatchSettings({ type: "SET_NAVIGATION_COLOR", payload: navigationColor })
    dispatchSettings({ type: "SET_NAVIGATION_BACKGROUND_COLOR", payload: themeColor })
    dispatchSettings({ type: "SET_GENERAL_FORM_DISPLAY", payload: displayGeneralForm })
  }, [dispatchSettings, navigationColor, themeColor, displayGeneralForm])

  return {
    themeColor,
  }
}

usePageSetup.propTypes = {
  themeColor: PropTypes.string.isRequired,
  navigationColor: PropTypes.string,
  displayGeneralForm: PropTypes.bool,
}
